import { ApiProductsCacheResponse } from '@ibe/api';
import dayjs, { Dayjs } from 'dayjs';
import {
  FIND_FIRST_AVAILABLE_PARAMETER,
  VARIANT_QUERY_PROP,
  VARIANT_QUERY_PROP_DELIMITER
} from '@/Util/globals';
import { ApiProduct } from '../../../api/model';
import { SortedCacheProductsByDate } from '@/components/SearchForResults/helperFns';

export const formatDate = (dateUnformatted: string): Dayjs => {
  const year = dateUnformatted.substring(0, 4);
  const month = dateUnformatted.substring(4, 6);
  const day = dateUnformatted.substring(6);
  return dayjs(`${year}-${month}-${day}`);
};

export const getEarliestPackage = (
  sortedProductPackages: SortedCacheProductsByDate,
  variants?: ApiProduct[]
): ApiProductsCacheResponse => {
  return (
    Object.entries(sortedProductPackages)
      .flatMap(([, value]) => value)
      .reduce(
        (total, current) => {
          return ((!!current.products?.[0]?.travelStartDate &&
            !!total?.products?.[0]?.travelStartDate &&
            dayjs(current.products?.[0]?.travelStartDate).unix() <
              dayjs(total.products?.[0]?.travelStartDate).unix() &&
            current.products?.some(product => (product.availability || 0) > 0)) ||
            (current.products?.some(product => (product.availability || 0) > 0) &&
              !total?.products?.some(product => (product.availability || 0) > 0)) ||
            !total?.products?.[0]?.travelStartDate) &&
            (!variants ||
              (variants?.length > 0 &&
                !variants.some(variant => variant.name === current.packageCode)))
            ? current
            : total;
        },
        undefined as ApiProductsCacheResponse | undefined
      ) || Object.entries(sortedProductPackages)?.[0]?.[1]?.[0]
  );
};

export const getEarliestPackageWithAvailabilities = (
  sortedProductPackages: SortedCacheProductsByDate
): ApiProductsCacheResponse | undefined => {
  return Object.entries(sortedProductPackages)
    .flatMap(([, value]) => value)
    .reduce(
      (total, current) => {
        return ((!!current.products?.[0]?.travelStartDate &&
          !!total?.products?.[0]?.travelStartDate &&
          dayjs(current.products?.[0]?.travelStartDate).unix() <
            dayjs(total.products?.[0]?.travelStartDate).unix()) ||
          !total?.products?.[0]?.travelStartDate) &&
          current.products?.some(product => (product.availability || 0) > 0)
          ? current
          : total;
      },
      undefined as ApiProductsCacheResponse | undefined
    );
};

const getPackageForDate = (
  sortedProductPackages: SortedCacheProductsByDate,
  dateFormatted: Dayjs,
  productCode?: string
) => {
  return Object.entries(sortedProductPackages)
    .flatMap(([, value]) => value)
    .find(productPackage => {
      const packageDate = dayjs(productPackage.products?.[0].travelStartDate);
      return (
        dateFormatted.isValid() &&
        packageDate.isValid() &&
        dateFormatted.isSame(packageDate, 'day') &&
        (!productCode || (!!productCode && productCode === productPackage.packageCode))
      );
    });
};

const getEarliestPackageForCode = (
  sortedProductPackages: SortedCacheProductsByDate,
  productCode: string,
  getEarliestAvailable?: boolean
) => {
  return Object.entries(sortedProductPackages)
    .flatMap(([, value]) => value)
    .filter(productPackage => {
      return productCode === productPackage.packageCode;
    })
    .reduce(
      (total, current) => {
        return ((!!current.products?.[0]?.travelStartDate &&
          !!total?.products?.[0]?.travelStartDate &&
          dayjs(current.products?.[0]?.travelStartDate).unix() <
            dayjs(total.products?.[0]?.travelStartDate).unix()) ||
          !total?.products?.[0]?.travelStartDate) &&
          (!getEarliestAvailable ||
            (getEarliestAvailable &&
              current.products?.some(product => (product.availability || 0) > 0)))
          ? current
          : total;
      },
      undefined as ApiProductsCacheResponse | undefined
    );
};

const findVariant = (
  identifier: string,
  product?: ApiProduct,
  variants?: ApiProduct[]
): { isVariant: boolean; foundVariant?: ApiProduct } => {
  let isVariant = false;
  const foundVariantIndex = variants?.findIndex(variant => {
    return (
      !!product &&
      !!identifier &&
      identifier.toLowerCase().trim() === variant.name?.toLowerCase()?.trim()
    );
  });
  const foundVariant = variants?.[foundVariantIndex !== undefined ? foundVariantIndex : -1];
  if (!!foundVariant && !!product) {
    isVariant = true;
    // replace product properties with variant properties
    Object.entries(foundVariant).forEach(([key, value]) => {
      if (
        !!value &&
        (typeof value === 'string' ||
          (Array.isArray(value) && value.length > 0) ||
          (typeof value === 'object' && Object.keys(value).length > 0)) &&
        !!product
      ) {
        product = { ...product, [key]: value };
      }
    });
  }
  return { isVariant, foundVariant };
};

const getSelectedPackageAndEnrichedProduct = (
  searchParams: Record<string, string | undefined>,
  sortedProductPackages: SortedCacheProductsByDate,
  product?: ApiProduct
): {
  selectedProductPackage: ApiProductsCacheResponse | undefined;
  isVariant: boolean;
  variant: ApiProduct | undefined;
} => {
  const variantQuery = searchParams[VARIANT_QUERY_PROP];
  let selectedProductPackage: ApiProductsCacheResponse | undefined;
  let isVariant;
  let foundVariant: ApiProduct | undefined;
  const { variants } = product || {};

  if (variantQuery) {
    const variantQuerySplit = variantQuery.split(VARIANT_QUERY_PROP_DELIMITER);
    // has product code and departure date
    if (variantQuerySplit.length === 2 && !!variants) {
      const identifier = variantQuerySplit[0];
      selectedProductPackage = getPackageForDate(
        sortedProductPackages,
        formatDate(variantQuerySplit[1]),
        identifier
      );

      const { isVariant: localIsVariant, foundVariant: localFoundVariant } = findVariant(
        identifier,
        product,
        variants
      );
      isVariant = localIsVariant;
      foundVariant = localFoundVariant;
    } else if (variantQuerySplit.length === 1) {
      if (formatDate(variantQuerySplit[0]).isValid()) {
        selectedProductPackage = getPackageForDate(
          sortedProductPackages,
          formatDate(variantQuerySplit[0]),
          product?.name
        );
        const { isVariant: localIsVariant, foundVariant: localFoundVariant } = findVariant(
          selectedProductPackage?.packageCode || '',
          product,
          variants
        );
        isVariant = localIsVariant;
        foundVariant = localFoundVariant;
      } else {
        selectedProductPackage = getEarliestPackageForCode(
          sortedProductPackages,
          variantQuerySplit[0],
          !!searchParams[FIND_FIRST_AVAILABLE_PARAMETER]
        );
        const { isVariant: localIsVariant, foundVariant: localFoundVariant } = findVariant(
          selectedProductPackage?.packageCode || '',
          product,
          variants
        );
        isVariant = localIsVariant;
        foundVariant = localFoundVariant;
      }
    } else {
      selectedProductPackage = getEarliestPackage(sortedProductPackages, variants);
      const { isVariant: localIsVariant, foundVariant: localFoundVariant } = findVariant(
        selectedProductPackage?.packageCode || '',
        product,
        variants
      );
      isVariant = localIsVariant;
      foundVariant = localFoundVariant;
    }
  } else {
    if (searchParams[FIND_FIRST_AVAILABLE_PARAMETER]) {
      selectedProductPackage = getEarliestPackageWithAvailabilities(sortedProductPackages);
      if (selectedProductPackage) {
        const { isVariant: localIsVariant, foundVariant: localFoundVariant } = findVariant(
          selectedProductPackage.packageCode || '',
          product,
          variants
        );
        isVariant = localIsVariant;
        foundVariant = localFoundVariant;
      } else {
        selectedProductPackage = getEarliestPackage(sortedProductPackages, variants);
        const { isVariant: localIsVariant, foundVariant: localFoundVariant } = findVariant(
          selectedProductPackage?.packageCode || '',
          product,
          variants
        );
        isVariant = localIsVariant;
        foundVariant = localFoundVariant;
      }
    } else {
      selectedProductPackage = getEarliestPackage(sortedProductPackages, variants);
      const { isVariant: localIsVariant, foundVariant: localFoundVariant } = findVariant(
        selectedProductPackage?.packageCode || '',
        product,
        variants
      );
      isVariant = localIsVariant;
      foundVariant = localFoundVariant;
    }
  }

  return { selectedProductPackage, isVariant, variant: foundVariant };
};

export default getSelectedPackageAndEnrichedProduct;
